import { Action } from '@ngrx/store';

import { UserModel } from '@fixiti/models';

export enum UserActionTypes {
    LoadUser = '[User] Load User',
    Logout = '[User] Logout',
    KickUser = '[User] Kick User',
}

export class LoadUser implements Action {
    readonly type = UserActionTypes.LoadUser;
    constructor(public payload: UserModel) {}
}

export class KickUser implements Action {
    readonly type = UserActionTypes.KickUser;
    constructor(public payload?: string) {}
}

export class Logout implements Action {
    readonly type = UserActionTypes.Logout;
}

export type UserAction = LoadUser | Logout | KickUser;

export const fromUserActions = {
    LoadUser,
    Logout,
    KickUser,
};
