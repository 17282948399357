"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var PermissionGuard = /** @class */ (function () {
    function PermissionGuard(store) {
        this.store = store;
    }
    PermissionGuard.prototype.canActivate = function (route, state) {
        return this.store.pipe(store_1.select(src_1.userQuery.activePermissions), operators_1.map(function (permissions) {
            var permission = route.data.permission;
            if (!permission) {
                throw new Error('Called PermissionGuard without permission name in route data');
            }
            return permission in permissions && permissions[permission];
        }));
    };
    PermissionGuard.ngInjectableDef = i0.defineInjectable({ factory: function PermissionGuard_Factory() { return new PermissionGuard(i0.inject(i1.Store)); }, token: PermissionGuard, providedIn: "root" });
    return PermissionGuard;
}());
exports.PermissionGuard = PermissionGuard;
