import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MembershipModel, ModulesModel, PermissionsModel, UserModel } from '@fixiti/models/src';
import { UserState } from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
const getUserState = createFeatureSelector<UserState>('user');

const getUser = createSelector(
    getUserState,
    (state: UserState): UserModel => {
        return state.current;
    }
);
const loggedIn = createSelector(
    getUserState,
    (state: UserState) => {
        return state.current != null;
    }
);
const activeMembership = createSelector(
    loggedIn,
    getUser,
    (isLoggedIn: boolean, user: UserModel): MembershipModel => {
        if (!isLoggedIn || !user.memberships || !user.memberships.length) {
            return null;
        }

        return user.memberships[0];
    }
);
const activePermissions = createSelector(
    activeMembership,
    (membership?: MembershipModel): PermissionsModel => {
        return membership ? membership.permissions : {};
    }
);
const activeModules = createSelector(
    activeMembership,
    (membership?: MembershipModel): ModulesModel => {
        return membership ? membership.modules : {};
    }
);
const activeSearchJwt = createSelector(
    activeMembership,
    (membership?: MembershipModel): string => {
        return membership ? membership.searchJWT : '';
    }
);

export const userQuery = {
    activeMembership,
    activeModules,
    activePermissions,
    activeSearchJwt,
    getUser,
    loggedIn,
};
