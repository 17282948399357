import { ModulesModel } from './modules.model';
import { PermissionsModel } from './permissions.model';

export class MembershipModel {
    id: number;
    customerId: number;
    customerName: string;
    logoUrl: string;
    primaryColor: string;
    secondaryColor: string;
    modules: ModulesModel;
    permissions: PermissionsModel;
    searchJWT: string;
}
