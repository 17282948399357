export class LandingPageActionsModel {
    cancelCreate: any;
    comment?: any;
    commentsLoad?: any;
    closeFilterMenu: any;
    create: any;
    deSelectRow: any;
    delete?: any;
    hideComments?: any;
    hideDelete?: any;
    init?: any;
    loadOptions?: any;
    openFilterMenu: any;
    selectRow: any;
    setPageDisplay: any;
    setQuerySetting: any;
    showCreate: any;
    showDelete?: any;
    update: any;
    // The above are all standards, but the user can add their own (mainly only usable in routing)
    [key: string]: any;
}
