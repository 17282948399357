"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./confirm.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./confirm.component");
var styles_ConfirmComponent = [i0.styles];
var RenderType_ConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmComponent, data: {} });
exports.RenderType_ConfirmComponent = RenderType_ConfirmComponent;
function View_ConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "form", [["class", "property-form"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "continue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue?"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "input", [["class", "btn btn-green"], ["type", "submit"], ["value", "Yes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "btn btn-red"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 3, 0, currVal_1); }); }
exports.View_ConfirmComponent_0 = View_ConfirmComponent_0;
function View_ConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-modal-confirmationn", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i3.ConfirmComponent, [], null, null)], null, null); }
exports.View_ConfirmComponent_Host_0 = View_ConfirmComponent_Host_0;
var ConfirmComponentNgFactory = i1.ɵccf("fixiti-modal-confirmationn", i3.ConfirmComponent, View_ConfirmComponent_Host_0, { title: "title", message: "message" }, { no: "no", yes: "yes" }, []);
exports.ConfirmComponentNgFactory = ConfirmComponentNgFactory;
