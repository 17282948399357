"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'User' feature state managed by NgRx
var getUserState = store_1.createFeatureSelector('user');
var ɵ0 = function (state) {
    return state.current;
};
exports.ɵ0 = ɵ0;
var getUser = store_1.createSelector(getUserState, ɵ0);
var ɵ1 = function (state) {
    return state.current != null;
};
exports.ɵ1 = ɵ1;
var loggedIn = store_1.createSelector(getUserState, ɵ1);
var ɵ2 = function (isLoggedIn, user) {
    if (!isLoggedIn || !user.memberships || !user.memberships.length) {
        return null;
    }
    return user.memberships[0];
};
exports.ɵ2 = ɵ2;
var activeMembership = store_1.createSelector(loggedIn, getUser, ɵ2);
var ɵ3 = function (membership) {
    return membership ? membership.permissions : {};
};
exports.ɵ3 = ɵ3;
var activePermissions = store_1.createSelector(activeMembership, ɵ3);
var ɵ4 = function (membership) {
    return membership ? membership.modules : {};
};
exports.ɵ4 = ɵ4;
var activeModules = store_1.createSelector(activeMembership, ɵ4);
var ɵ5 = function (membership) {
    return membership ? membership.searchJWT : '';
};
exports.ɵ5 = ɵ5;
var activeSearchJwt = store_1.createSelector(activeMembership, ɵ5);
exports.userQuery = {
    activeMembership: activeMembership,
    activeModules: activeModules,
    activePermissions: activePermissions,
    activeSearchJwt: activeSearchJwt,
    getUser: getUser,
    loggedIn: loggedIn,
};
