<div class="popup-holder" (click)="doClose($event)">
    <div class="popup-bg">&nbsp;</div>
    <div class="popup-wrap" [ngStyle]="{ 'max-width': _width ? _width : undefined }">
        <div
            class="popup-content"
            (click)="squelch($event)"
            [ngStyle]="{ 'min-height': minHeight ? minHeight + 'px' : undefined }"
        >
            <button class="close-btn" (click)="doClose($event)">&nbsp;</button>
            <ng-container #componentContainer></ng-container>
        </div>
    </div>
</div>
