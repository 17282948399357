import { SearchAction, SearchActionTypes } from './search.actions';

import { ResultModel } from '../models';

export const SEARCH_FEATURE_KEY = 'search';

export interface SearchState {
    results: ResultModel[];
    loading: boolean;
    error?: any;
}

export interface SearchPartialState {
    readonly [SEARCH_FEATURE_KEY]: SearchState;
}

export const initialState: SearchState = {
    results: [],
    loading: false,
};

const reducers = {
    [SearchActionTypes.Loaded]: (state: any, action: any): any => {
        return {
            ...state,
            loading: false,
            results: action.results,
        };
    },
    [SearchActionTypes.LoadError]: (state: any, action: any): any => {
        return {
            ...state,
            loading: false,
            error: action.error,
        };
    },
    [SearchActionTypes.Search]: (state: any, action: any): any => {
        return {
            ...state,
            loading: true,
        };
    },
};

export function searchReducer(state: SearchState = initialState, action: any): any {
    if (action.type in reducers) {
        return reducers[action.type](state, action);
    }

    return state;
}
