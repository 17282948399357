/// <reference path="../../../../../models/src/lib/api/paginated-server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/person.ts" />
/// <reference path="../../../../../models/src/lib/api/widget.ts" />
/// <reference path="../../../../../models/src/lib/api/user.ts" />

import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, race, zip } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { catchError, filter, map, switchMap, take, timeout } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ModalService } from '@fixiti/elements/modal/src';
import { RestApiBase } from '@fixiti/api/rest/rest-api.base';
import { SubmitImagesProgress } from '@fixiti/actions/src';
import { selectConfiguration } from '@fixiti/state/settings/src';

@Injectable({
    providedIn: 'root',
})
export class ImagesRestApiService extends RestApiBase {
    constructor(
        protected store: Store<any>,
        protected modalService: ModalService,
        protected http: HttpClient
    ) {
        super(store, modalService);
    }

    uploadImage({
        file,
        data,
        type,
        index,
    }: {
        file: File;
        data: string;
        type?: string;
        index: number;
    }): Observable<number> {
        return zip(
            this.serverObs,
            this.defaultHttpHeaders().pipe(
                map(headers =>
                    headers
                        .append('dataType', 'json')
                        .append('processData', 'false')
                        .append('contentType', (file && file.type) || type)
                        .append('Content-Type', (file && file.type) || type)
                )
            )
        ).pipe(
            switchMap(([server, headers]) => {
                const req = new HttpRequest('put', `${server}api/1.0/images/single_upload`, data, {
                    headers,
                    reportProgress: true,
                });
                return this.http.request(req);
            }),
            this.retryStrategy(),
            this.checkForError(),
            map((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                    const progress = Math.round((100 * event.loaded) / event.total);
                    this.store.dispatch(new SubmitImagesProgress({ progress, index }));
                    return null;
                } else if (event instanceof HttpResponse) {
                    this.store.dispatch(new SubmitImagesProgress({ progress: 100, index }));
                    return +event.body.data.id;
                } else {
                    return null;
                }
            }),
            filter(item => !!item),
            catchError(error => this.handleError(error))
        );
    }

    rawUpload(file: File, callback: (progress: number) => void): Observable<number> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) => {
                const req = new HttpRequest('put', `${server}api/1.0/images/raw_single_upload`, file, {
                    headers,
                    reportProgress: true,
                });
                return this.http.request(req);
            }),
            this.retryStrategy(),
            map((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                    const progress = Math.round((100 * event.loaded) / event.total);
                    callback(progress);
                    return null;
                }

                if (event instanceof HttpResponse) {
                    const json = event.body;
                    if (json.status === 'failure') {
                        throw new Error('Whoops, we made a mistake somewhere.  Try again!');
                    }
                    if (json.status === 'error') {
                        throw new Error(json.errorMessage);
                    }
                    return +json.data.id;
                }

                return null;
            }),
            filter(item => !!item),
            catchError(error => this.handleError(error))
        );
    }

    defaultHttpParameters() {
        return this.store.pipe(
            select(selectConfiguration),
            filter(state => !!state),
            map(user => ({
                Authorization: `Bearer ${user.apiKey}`,
                MembershipId: user.membershipId,
            })),
            timeout(1000),
            take(1)
        );
    }
}
