"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./property.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./property.component");
var i4 = require("@ngrx/store");
var styles_PropertyComponent = [i0.styles];
var RenderType_PropertyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropertyComponent, data: {} });
exports.RenderType_PropertyComponent = RenderType_PropertyComponent;
function View_PropertyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Investor: ", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.investor.firstName; var currVal_1 = _co.investor.lastName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PropertyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Resident: ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.firstName; var currVal_1 = _v.context.$implicit.lastName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PropertyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "search-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", " ", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "ul", [["class", "item-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Property"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertyComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertyComponent_2)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.investor && (_co.investor.firstName || _co.investor.lastName)); _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.residents; _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frontElevationUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.address; var currVal_2 = _co.city; var currVal_3 = _co.state; var currVal_4 = _co.zip; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
exports.View_PropertyComponent_0 = View_PropertyComponent_0;
function View_PropertyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-search-property", [], null, null, null, View_PropertyComponent_0, RenderType_PropertyComponent)), i1.ɵdid(1, 49152, null, 0, i3.PropertyComponent, [i4.Store], null, null)], null, null); }
exports.View_PropertyComponent_Host_0 = View_PropertyComponent_Host_0;
var PropertyComponentNgFactory = i1.ɵccf("fixiti-search-property", i3.PropertyComponent, View_PropertyComponent_Host_0, { result: "result" }, {}, []);
exports.PropertyComponentNgFactory = PropertyComponentNgFactory;
