"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var portal_1 = require("@angular/cdk/portal");
var rxjs_1 = require("rxjs");
var modal_component_1 = require("../components/modal/modal.component");
var error_component_1 = require("../components/error/error.component");
var message_component_1 = require("../components/message/message.component");
var confirm_component_1 = require("../components/confirm/confirm.component");
var i0 = require("@angular/core");
var ModalService = /** @class */ (function () {
    function ModalService(componentFactoryResolver, applicationRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.applicationRef = applicationRef;
        this.injector = injector;
        this.ngUnsubscribe = new rxjs_1.Subject();
        this.events = new rxjs_1.Subject();
        this.close = new rxjs_1.Subject();
        this.modalPortal = new portal_1.ComponentPortal(modal_component_1.ModalComponent);
        this.bodyPortalHost = new portal_1.DomPortalHost(document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
    }
    ModalService.prototype.error = function (error) {
        this.show(error_component_1.ErrorComponent, { error: error });
    };
    ModalService.prototype.message = function (message, title) {
        this.show(message_component_1.MessageComponent, { message: message, title: title });
    };
    /**
     * Launches a modal with a message and which asks for a confirmation.
     *
     * Returns a promise that will be either resolved (if the user selects "Yes")
     * or rejected (if the user selects "No" or closes the modal).
     */
    ModalService.prototype.confirm = function (message, title) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.show(confirm_component_1.ConfirmComponent, { message: message, title: title }, ['yes', 'no']);
            _this.events.subscribe(function (event) {
                _this.hide();
                if (event.name === 'yes') {
                    resolve();
                }
                else {
                    reject();
                }
            });
            _this.close.subscribe(function () {
                reject();
            });
        });
    };
    ModalService.prototype.show = function (componentClass, inputData, outputs) {
        var _this = this;
        if (this.componentRef) {
            this.hide();
        }
        this.componentClass = componentClass;
        this.componentRef = this.bodyPortalHost.attach(this.modalPortal);
        this.componentRef.instance.componentClass = this.componentClass;
        if (outputs) {
            this.componentRef.instance.events = outputs;
            this.componentRef.instance.childEvents.subscribe(function (event) {
                _this.events.next(event);
            });
        }
        if (inputData) {
            this.setComponentInput(inputData);
        }
        this.componentRef.instance.close.subscribe(function () {
            _this.hide();
            _this.close.next(_this.componentClass);
        });
    };
    ModalService.prototype.setComponentInput = function (inputData) {
        if (!this.componentRef) {
            throw new Error('Cannot set component input: modal is not open');
        }
        this.componentRef.instance.componentData = inputData;
        this.componentRef.changeDetectorRef.detectChanges();
    };
    ModalService.prototype.hide = function () {
        this.bodyPortalHost.detach();
        this.componentRef = null;
        this.componentClass = null;
    };
    ModalService.ngInjectableDef = i0.defineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.ApplicationRef), i0.inject(i0.INJECTOR)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
exports.ModalService = ModalService;
