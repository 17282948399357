"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var nx_1 = require("@nrwl/nx");
var operators_1 = require("rxjs/operators");
var search_actions_1 = require("./search.actions");
var rest_api_service_1 = require("../services/rest-api.service");
var SearchEffects = /** @class */ (function () {
    function SearchEffects(actions$, dataPersistence, restApiService) {
        var _this = this;
        this.actions$ = actions$;
        this.dataPersistence = dataPersistence;
        this.restApiService = restApiService;
        this.search$ = this.dataPersistence.fetch(search_actions_1.SearchActionTypes.Search, {
            run: function (action, state) {
                return _this.restApiService.search(action.term).pipe(operators_1.map(function (results) {
                    return new search_actions_1.Loaded(results);
                }));
            },
            onError: function (action, error) {
                return new search_actions_1.LoadError(error);
            },
        });
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], SearchEffects.prototype, "search$", void 0);
    return SearchEffects;
}());
exports.SearchEffects = SearchEffects;
