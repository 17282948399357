<div class="search-result">
    <div class="img-holder"><img [src]="frontElevationUrl" alt="" /></div>
    <div class="inner">
        <div class="description">
            <h2 (click)="select($event)" class="clickable">{{ workOrderNumber }}</h2>
            <ul class="item-list">
                <li>Job</li>
                <li>{{ address }} {{ city }} {{ state }} {{ zip }}</li>
                <li>Status: {{ status }}</li>
                <li *ngIf="residentName">
                    Resident:
                    <a href="'mailto' + residentEmail" *ngIf="residentEmail; else: noEmail">
                        {{ residentName }}
                    </a>
                    <ng-template #noEmail> {{ residentName }} </ng-template>
                </li>
                <li *ngIf="scheduleStartTime">
                    Scheduled: {{ scheduleStartTime | date }} {{ scheduleStartTime | date: 'shortTime' }} -
                    {{ scheduleEndTime | date: 'shortTime' }}
                </li>
            </ul>
        </div>
    </div>
</div>
