"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var src_1 = require("@fixiti/actions/src");
var models_1 = require("../../../models/");
var InvestorComponent = /** @class */ (function () {
    function InvestorComponent(store) {
        this.store = store;
        this.resourceId = 0;
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.profileUrl = '';
    }
    Object.defineProperty(InvestorComponent.prototype, "result", {
        set: function (result) {
            this.resourceId = result.resourceId;
            this.email = result.data.email || '';
            this.firstName = result.data.first_name || '';
            this.lastName = result.data.last_name || '';
            this.phone = result.data.phone || '';
            this.profileUrl = result.data.profile_url || '';
        },
        enumerable: true,
        configurable: true
    });
    InvestorComponent.prototype.select = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new src_1.Go({ path: ["investors/view/" + this.resourceId] }));
    };
    return InvestorComponent;
}());
exports.InvestorComponent = InvestorComponent;
