"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.css.ngstyle");
var i1 = require("./assets/css/style.css.ngstyle");
var i2 = require("@angular/core");
var i3 = require("../../../../libs/elements/search/src/lib/components/search/search.component.ngfactory");
var i4 = require("../../../../libs/elements/search/src/lib/components/search/search.component");
var i5 = require("@ngrx/store");
var i6 = require("./components/main-menu-toggle/main-menu-toggle.component.ngfactory");
var i7 = require("./components/main-menu-toggle/main-menu-toggle.component");
var i8 = require("./components/main-menu/main-menu.component.ngfactory");
var i9 = require("./components/main-menu/main-menu.component");
var i10 = require("@angular/common");
var i11 = require("@angular/router");
var i12 = require("./app.component");
var styles_AppComponent = [i0.styles, i1.styles];
var RenderType_AppComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "fixiti-search", [], null, null, null, i3.View_SearchComponent_0, i3.RenderType_SearchComponent)), i2.ɵdid(1, 245760, null, 0, i4.SearchComponent, [i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 25, "div", [["class", "header-menu"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "fixiti-main-menu-toggle", [], null, null, null, i6.View_MainMenuToggleComponent_0, i6.RenderType_MainMenuToggleComponent)), i2.ɵdid(2, 49152, null, 0, i7.MainMenuToggleComponent, [i5.Store], null, null), (_l()(), i2.ɵeld(3, 0, null, null, 1, "fixiti-main-menu", [], null, null, null, i8.View_MainMenuComponent_0, i8.RenderType_MainMenuComponent)), i2.ɵdid(4, 49152, null, 0, i9.MainMenuComponent, [i5.Store], null, null), (_l()(), i2.ɵeld(5, 0, null, null, 20, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 19, "nav", [["id", "nav"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 18, "ul", [], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 1, "a", [["href", "/dashboard"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Dashboard"])), (_l()(), i2.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 1, "a", [["href", "/properties"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Properties"])), (_l()(), i2.ɵeld(14, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 1, "a", [["href", "/maintenance"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Maintenance"])), (_l()(), i2.ɵeld(17, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i2.ɵeld(18, 0, null, null, 1, "a", [["href", "/leasing"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Leasing"])), (_l()(), i2.ɵeld(20, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i2.ɵeld(21, 0, null, null, 1, "a", [["href", "/accounting"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Accounting"])), (_l()(), i2.ɵeld(23, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i2.ɵeld(24, 0, null, null, 1, "a", [["href", "/reporting"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Reporting"]))], null, null); }
function View_AppComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 15, "div", [["id", "wrapper"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 11, "header", [["id", "header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 6, "div", [["class", "top-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 2, "strong", [["class", "logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["height", "113"], ["src", "assets/img/logo.svg"], ["width", "217"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i2.ɵdid(8, 16384, null, 0, i10.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), i2.ɵpid(131072, i10.AsyncPipe, [i2.ChangeDetectorRef]), (_l()(), i2.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i2.ɵdid(11, 16384, null, 0, i10.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), i2.ɵpid(131072, i10.AsyncPipe, [i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(13, 0, null, null, 2, "main", [["id", "main"]], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i2.ɵdid(15, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i2.ViewContainerRef, i2.ComponentFactoryResolver, [8, null], i2.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 8, 0, i2.ɵnov(_v, 9).transform(_co.isLoggedIn$)); _ck(_v, 8, 0, currVal_0); var currVal_1 = i2.ɵunv(_v, 11, 0, i2.ɵnov(_v, 12).transform(_co.isLoggedIn$)); _ck(_v, 11, 0, currVal_1); _ck(_v, 15, 0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "fixiti-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i2.ɵdid(1, 114688, null, 0, i12.AppComponent, [i5.Store, i11.ActivatedRoute, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i2.ɵccf("fixiti-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
