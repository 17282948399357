"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./modal.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./modal.component");
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
exports.RenderType_ModalComponent = RenderType_ModalComponent;
function View_ModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { componentContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "popup-holder"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "popup-bg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "popup-wrap"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "max-width": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "popup-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.squelch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { "min-height": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(12, 16777216, [[1, 3], ["componentContainer", 1]], null, 0, null, null, null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 6, 0, (_co._width ? _co._width : undefined)); _ck(_v, 5, 0, currVal_0); var currVal_1 = _ck(_v, 9, 0, (_co.minHeight ? (_co.minHeight + "px") : undefined)); _ck(_v, 8, 0, currVal_1); }, null); }
exports.View_ModalComponent_0 = View_ModalComponent_0;
function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 4898816, null, 0, i3.ModalComponent, [i1.ComponentFactoryResolver, i1.ChangeDetectorRef], null, null)], null, null); }
exports.View_ModalComponent_Host_0 = View_ModalComponent_Host_0;
var ModalComponentNgFactory = i1.ɵccf("fixiti-modal", i3.ModalComponent, View_ModalComponent_Host_0, { componentClass: "componentClass", componentData: "componentData", events: "events", minHeight: "minHeight", width: "width" }, { close: "close", childEvents: "childEvents" }, []);
exports.ModalComponentNgFactory = ModalComponentNgFactory;
