import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { Go } from '@fixiti/actions/src';
import { ResultModel } from '../../../models/';

@Component({
    selector: 'fixiti-search-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.css'],
})
export class PropertyComponent {
    constructor(private store: Store<any>) {}

    resourceId = null;
    address = '';
    city = '';
    state = '';
    zip = '';
    frontElevationUrl = '';
    investor = null;
    residents = [];

    @Input()
    set result(result: ResultModel) {
        this.resourceId = result.resourceId;
        this.address = result.data.address || '';
        this.city = result.data.city || '';
        this.state = result.data.state || '';
        this.zip = result.data.zip || '';
        this.frontElevationUrl = result.data.front_elevation_url || '';
        const investor = result.data.investor || {};
        this.investor = {
            firstName: investor.first_name || '',
            lastName: investor.last_name || '',
            email: investor.email || '',
            phone: investor.phone || '',
        };
        this.residents = (investor.resident_list || []).map(resident => {
            return {
                firstName: resident.first_name || '',
                lastName: resident.last_name || '',
                email: resident.email || '',
                phone: resident.phone || '',
            };
        });
    }

    select(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.store.dispatch(new Go({ path: [`properties/view/${this.resourceId}`] }));
    }
}
