import { MembershipModel } from './membership.model';

export class UserModel {
    apiToken: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    cellPhone: string;
    profileUrl: string;
    memberships: MembershipModel[];
    membershipId?: number;
}
