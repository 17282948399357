"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var search_selectors_1 = require("../../+state/search.selectors");
var search_actions_1 = require("../../+state/search.actions");
var SearchComponent = /** @class */ (function () {
    function SearchComponent(store) {
        this.store = store;
        this.ngUnsubscribe = new rxjs_1.Subject();
        this.searchTerms = new rxjs_1.Subject();
        this.results$ = this.store.pipe(store_1.select(search_selectors_1.searchQuery.results));
        this.showResults = false;
    }
    SearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchTerms
            .pipe(operators_1.takeUntil(this.ngUnsubscribe), operators_1.filter(function (term) { return term.length > 2; }), operators_1.distinctUntilChanged(), operators_1.debounceTime(250))
            .subscribe(function (term) {
            _this.store.dispatch(new search_actions_1.Search(term));
        });
    };
    SearchComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SearchComponent.prototype.searchTerm = function (term) {
        this.showResults = term.length > 2;
        this.searchTerms.next(term);
    };
    return SearchComponent;
}());
exports.SearchComponent = SearchComponent;
