import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { SearchPartialState } from './search.reducer';
import { Loaded, LoadError, Search, SearchActionTypes } from './search.actions';
import { RestApiService } from '../services/rest-api.service';

@Injectable()
export class SearchEffects {
    @Effect() search$ = this.dataPersistence.fetch(SearchActionTypes.Search, {
        run: (action: Search, state: SearchPartialState) => {
            return this.restApiService.search(action.term).pipe(
                map(results => {
                    return new Loaded(results);
                })
            );
        },
        onError: (action: any, error: any) => {
            return new LoadError(error);
        },
    });

    constructor(
        private actions$: Actions,
        private dataPersistence: DataPersistence<SearchPartialState>,
        private restApiService: RestApiService
    ) {}
}
