"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./manager.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./manager.component");
var i4 = require("@ngrx/store");
var styles_ManagerComponent = [i0.styles];
var RenderType_ManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManagerComponent, data: {} });
exports.RenderType_ManagerComponent = RenderType_ManagerComponent;
function View_ManagerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("mailto: " + _co.email); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.email; _ck(_v, 1, 0, currVal_1); }); }
function View_ManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Email: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManagerComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.email; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ManagerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("tel: " + _co.phone); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.phone; _ck(_v, 1, 0, currVal_1); }); }
function View_ManagerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Phone: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManagerComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.phone; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "search-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "ul", [["class", "item-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Employee"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManagerComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManagerComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.email; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.phone; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.firstName; var currVal_2 = _co.lastName; _ck(_v, 6, 0, currVal_1, currVal_2); }); }
exports.View_ManagerComponent_0 = View_ManagerComponent_0;
function View_ManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-search-result-manager", [], null, null, null, View_ManagerComponent_0, RenderType_ManagerComponent)), i1.ɵdid(1, 49152, null, 0, i3.ManagerComponent, [i4.Store], null, null)], null, null); }
exports.View_ManagerComponent_Host_0 = View_ManagerComponent_Host_0;
var ManagerComponentNgFactory = i1.ɵccf("fixiti-search-result-manager", i3.ManagerComponent, View_ManagerComponent_Host_0, { result: "result" }, {}, []);
exports.ManagerComponentNgFactory = ManagerComponentNgFactory;
