"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserActionTypes;
(function (UserActionTypes) {
    UserActionTypes["LoadUser"] = "[User] Load User";
    UserActionTypes["Logout"] = "[User] Logout";
    UserActionTypes["KickUser"] = "[User] Kick User";
})(UserActionTypes = exports.UserActionTypes || (exports.UserActionTypes = {}));
var LoadUser = /** @class */ (function () {
    function LoadUser(payload) {
        this.payload = payload;
        this.type = UserActionTypes.LoadUser;
    }
    return LoadUser;
}());
exports.LoadUser = LoadUser;
var KickUser = /** @class */ (function () {
    function KickUser(payload) {
        this.payload = payload;
        this.type = UserActionTypes.KickUser;
    }
    return KickUser;
}());
exports.KickUser = KickUser;
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = UserActionTypes.Logout;
    }
    return Logout;
}());
exports.Logout = Logout;
exports.fromUserActions = {
    LoadUser: LoadUser,
    Logout: Logout,
    KickUser: KickUser,
};
