"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var src_1 = require("@fixiti/state/sidenav/src");
var src_2 = require("@fixiti/actions/src");
var MainMenuComponent = /** @class */ (function () {
    function MainMenuComponent(store) {
        this.store = store;
        this.isOpen$ = this.store.pipe(store_1.select(src_1.sidenavQuery.getIsSidenavOpen));
    }
    MainMenuComponent.prototype.close = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new src_2.ToggleSidenav());
    };
    MainMenuComponent.prototype.go = function (url, event) {
        this.close(event);
        this.store.dispatch(new src_2.Go({ path: [url] }));
    };
    MainMenuComponent.prototype.logout = function (event) {
        this.close(event);
        this.store.dispatch(new src_2.Logout());
        this.store.dispatch(new src_2.Go({ path: ['/login'] }));
    };
    return MainMenuComponent;
}());
exports.MainMenuComponent = MainMenuComponent;
