import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { filter, map, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ModalService } from '@fixiti/elements/modal/src';
import { ImagesRestApiService } from './images-rest-api.service';
import { selectConfiguration } from '@fixiti/state/settings/src';
import { userQuery } from '@fixiti/state/user/src';

@Injectable({
    providedIn: 'root',
})
export class PmpImagesRestApiService extends ImagesRestApiService {
    constructor(
        protected store: Store<any>,
        protected modalService: ModalService,
        protected http: HttpClient
    ) {
        super(store, modalService, http);
    }

    defaultHttpParameters() {
        return this.store.pipe(
            select(userQuery.getUser),
            filter(state => !!state),
            map(user => ({
                Authorization: `Bearer ${user.apiToken}`,
                MembershipId: user.memberships[0].id,
                CustomerId: user.memberships[0].customerId,
            })),
            timeout(1000)
        );
    }
}
