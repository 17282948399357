import { EnvironmentAction, EnvironmentActionTypes } from '@fixiti/actions/src';

export const ENVIRONMENT_FEATURE_KEY = 'environment';

/**
 * Interface for the 'Environment' data used in
 *  - EnvironmentState, and
 *  - environmentReducer
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface EnvironmentState {
    current: {
        production?: boolean;
        apiUrl?: string;
        socketsUrl?: string;
        searchUrl?: string;
    };
    loaded: boolean;
}

export interface EnvironmentPartialState {
    readonly [ENVIRONMENT_FEATURE_KEY]: EnvironmentState;
}

export const initialState: EnvironmentState = {
    current: {},
    loaded: false,
};

export function environmentReducer(
    state: EnvironmentState = initialState,
    action: EnvironmentAction
): EnvironmentState {
    switch (action.type) {
        case EnvironmentActionTypes.LoadEnvironment: {
            state = {
                ...state,
                current: action.payload,
                loaded: true,
            };
            break;
        }
    }
    return state;
}
