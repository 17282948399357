"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./main-menu.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./main-menu.component");
var i4 = require("@ngrx/store");
var styles_MainMenuComponent = [i0.styles];
var RenderType_MainMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainMenuComponent, data: {} });
exports.RenderType_MainMenuComponent = RenderType_MainMenuComponent;
function View_MainMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "drop-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "click-detector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 40, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico01.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "/residents"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/residents", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Residents"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico02.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["href", "/investors"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/investors", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Investors"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico03.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [["href", "/users"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/users", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Users"])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico04.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "a", [["href", "/roles"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/roles", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Roles"])), (_l()(), i1.ɵeld(23, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico06.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "a", [["href", "/surveys"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/surveys", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Satisfaction Surveys"])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico03.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "a", [["href", "/profile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/profile", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Profile"])), (_l()(), i1.ɵeld(33, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico05.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "a", [["href", "/account"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.go("/account", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Account"])), (_l()(), i1.ɵeld(38, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 1, "span", [["class", "ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/icons/ico07.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], null, null); }
function View_MainMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isOpen$)); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_MainMenuComponent_0 = View_MainMenuComponent_0;
function View_MainMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-main-menu", [], null, null, null, View_MainMenuComponent_0, RenderType_MainMenuComponent)), i1.ɵdid(1, 49152, null, 0, i3.MainMenuComponent, [i4.Store], null, null)], null, null); }
exports.View_MainMenuComponent_Host_0 = View_MainMenuComponent_Host_0;
var MainMenuComponentNgFactory = i1.ɵccf("fixiti-main-menu", i3.MainMenuComponent, View_MainMenuComponent_Host_0, {}, {}, []);
exports.MainMenuComponentNgFactory = MainMenuComponentNgFactory;
