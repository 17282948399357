import 'hammerjs';

// import * as Sentry from '@sentry/browser';

import { AnonymousGuard, AuthGuard, GuardsAuthModule } from '@fixiti/guards/auth/src';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { GuardsReadModule, ReadGuard } from '@fixiti/guards/read';
import { GuardsPermissionModule, PermissionGuard } from '@fixiti/guards/permission/src';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { initialState as appInitialState, appReducer, metaReducers } from './+state/app.reducer';

import { AppComponent } from './app.component';
import { AppEffects } from './+state/app.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DashComponent } from './components/dash/dash.component';
import { DragulaModule } from 'ng2-dragula';
import { EffectsModule } from '@ngrx/effects';
import { ElementsModalModule } from '@fixiti/elements/modal/src';
import { FilesModule } from '@fixiti/state/files/src';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoComponent } from './components/logo/logo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationListComponent } from './components/navigation-list/navigation-list.component';
import { NxModule } from '@nrwl/nx';
import { PhotoDialogModule } from '@fixiti/elements/photo-dialog/src';
import { ElementsSearchModule } from '@fixiti/elements/search/src';
import { PicaModule } from '@fixiti/external/pica/src';
import { RoutingModule } from '@fixiti/state/routing/src';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsModule } from '@fixiti/state/settings/src';
import { SharedGraphicsLogoModule } from '@fixiti/shared/graphics/logo/src';
import { StateEnvironmentModule } from '@fixiti/state/environment/src';
import { StatePageSettingsModule } from '@fixiti/state/page-settings/src';
import { StatePermissionsModule } from '@fixiti/state/permissions/src';
import { StateRolesModule } from '@fixiti/state/roles/src';
import { StateSidenavModule } from '@fixiti/state/sidenav/src';
import { StateUserModule } from '@fixiti/state/user/src';
import { StateImporterModule } from '@fixiti/state/importer/src';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { environment } from '@env/environment';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { library } from '@fortawesome/fontawesome-svg-core';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MainMenuToggleComponent } from './components/main-menu-toggle/main-menu-toggle.component';

// Sentry.init({
//     dsn: 'https://e11270d385704568a176fdfd4ec5242e@sentry.io/1323643',
// });

library.add(faBars, faSignOutAlt);

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     constructor() {}
//     handleError(error) {
//         console.log(error);
//         Sentry.captureException(error.originalError || error);
//         throw error;
//     }
// }

export const routes = [
    {
        path: 'properties',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/properties/src/lib/pmp-properties.module#PmpPropertiesModule',
        data: { permission: 'properties' },
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: '@fixiti/pmp/dashboard/src/lib/dashboard.module#DashboardModule',
    },
    {
        path: 'maintenance',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/jobs/src/lib/pmp-jobs.module#PmpJobsModule',
        data: { permission: 'jobs' },
    },
    {
        path: 'residents',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/residents/src/lib/residents.module#ResidentsModule',
        data: { permission: 'residents' },
    },
    {
        path: 'investors',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/investors/src/lib/investors.module#InvestorsModule',
        data: { permission: 'investors' },
    },
    {
        path: 'users',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/users/src/lib/pages-users.module#PagesUsersModule',
        data: { permission: 'users' },
    },
    {
        path: 'roles',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/roles/src/lib/pages-roles.module#PagesRolesModule',
        data: { permission: 'permissions' },
    },
    {
        path: 'surveys',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/surveys/src/lib/pmp-surveys.module#PmpSurveysModule',
        data: { permission: 'jobs' },
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: '@fixiti/pmp/profile/src/lib/pmp-profile.module#PmpProfileModule',
    },
    {
        path: 'account',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: '@fixiti/pmp/account/src/lib/pmp-account.module#PmpAccountModule',
        data: { permission: 'billing' },
    },
    {
        path: 'login',
        canActivate: [AnonymousGuard],
        loadChildren: '@fixiti/pmp/login/src/lib/login.module#LoginModule',
    },
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: '**', redirectTo: 'dashboard' },
];

const MATERIAL = [
    MatToolbarModule,
    MatTabsModule,
    MatSidenavModule,
    MatButtonModule,
    MatTooltipModule,
    MatListModule,
];

const imports = [
    ...MATERIAL,
    GuardsPermissionModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedGraphicsLogoModule,
    ElementsSearchModule,
    FontAwesomeModule,
    ElementsModalModule,
    NxModule.forRoot(),
    RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
    }),
    StoreModule.forRoot(
        {
            app: appReducer,
        },
        {
            initialState: { app: appInitialState },
            metaReducers,
        }
    ),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
        name: 'PMP',
        logOnly: environment.production,
    }),
    GuardsAuthModule,
    GuardsReadModule,
    RoutingModule.forRoot(),
    StateSidenavModule,
    StateUserModule,
    //     StateWorkOrdersModule,
    StateRolesModule,
    StatePermissionsModule,
    StatePageSettingsModule,
    StateEnvironmentModule,
    StateImporterModule,
    PicaModule.forRoot(),
    FilesModule.forRoot(),
    PhotoDialogModule.forRoot(),
    SettingsModule.forRoot(),
    //     ApiRestImagesModule.forRoot(),
    //     ApiRestPmpModule.forRoot(),
    DragulaModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
    }),
];

@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        DashComponent,
        LogoComponent,
        NavigationComponent,
        NavigationListComponent,
        MainMenuComponent,
        MainMenuToggleComponent,
    ],
    imports,
    providers: [
        AppEffects,
        //         { provide: ErrorHandler, useClass: SentryErrorHandler }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
