"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./search.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../result/result.component.ngfactory");
var i3 = require("../result/result.component");
var i4 = require("../../services/result-component.service");
var i5 = require("@angular/common");
var i6 = require("./search.component");
var i7 = require("@ngrx/store");
var styles_SearchComponent = [i0.styles];
var RenderType_SearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchComponent, data: {} });
exports.RenderType_SearchComponent = RenderType_SearchComponent;
function View_SearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fixiti-search-result", [], null, null, null, i2.View_ResultComponent_0, i2.RenderType_ResultComponent)), i1.ɵdid(2, 704512, null, 0, i3.ResultComponent, [i1.ComponentFactoryResolver, i4.ResultComponentService], { result: [0, "result"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SearchComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.results$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "form", [["class", "search-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["placeholder", "Universal Search Bar\u2026"], ["type", "text"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.searchTerm($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["height", "22"], ["src", "assets/icons/ico-search.svg"], ["width", "25"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showResults; _ck(_v, 6, 0, currVal_0); }, null); }
exports.View_SearchComponent_0 = View_SearchComponent_0;
function View_SearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-search", [], null, null, null, View_SearchComponent_0, RenderType_SearchComponent)), i1.ɵdid(1, 245760, null, 0, i6.SearchComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SearchComponent_Host_0 = View_SearchComponent_Host_0;
var SearchComponentNgFactory = i1.ɵccf("fixiti-search", i6.SearchComponent, View_SearchComponent_Host_0, {}, {}, []);
exports.SearchComponentNgFactory = SearchComponentNgFactory;
