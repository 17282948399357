"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ConfirmComponent = /** @class */ (function () {
    function ConfirmComponent() {
        this.no = new core_1.EventEmitter();
        this.yes = new core_1.EventEmitter();
    }
    ConfirmComponent.prototype.squelch = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };
    ConfirmComponent.prototype.confirm = function (event) {
        this.squelch(event);
        this.yes.emit();
    };
    ConfirmComponent.prototype.cancel = function (event) {
        this.squelch(event);
        this.no.emit();
    };
    return ConfirmComponent;
}());
exports.ConfirmComponent = ConfirmComponent;
