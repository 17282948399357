import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { userQuery } from '@fixiti/state/user/src';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard {
    constructor(private store: Store<any>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(userQuery.activePermissions),
            map(permissions => {
                const permission = route.data.permission;
                if (!permission) {
                    throw new Error('Called PermissionGuard without permission name in route data');
                }
                return permission in permissions && permissions[permission];
            })
        );
    }
}
