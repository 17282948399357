"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SearchActionTypes;
(function (SearchActionTypes) {
    SearchActionTypes["Loaded"] = "[Search] Loaded";
    SearchActionTypes["LoadError"] = "[Search] Load Error";
    SearchActionTypes["Search"] = "[Search] Search";
})(SearchActionTypes = exports.SearchActionTypes || (exports.SearchActionTypes = {}));
var Loaded = /** @class */ (function () {
    function Loaded(results) {
        this.results = results;
        this.type = SearchActionTypes.Loaded;
    }
    return Loaded;
}());
exports.Loaded = Loaded;
var LoadError = /** @class */ (function () {
    function LoadError(error) {
        this.error = error;
        this.type = SearchActionTypes.LoadError;
    }
    return LoadError;
}());
exports.LoadError = LoadError;
var Search = /** @class */ (function () {
    function Search(term) {
        this.term = term;
        this.type = SearchActionTypes.Search;
    }
    return Search;
}());
exports.Search = Search;
exports.fromSearchActions = {
    Loaded: Loaded,
    LoadError: LoadError,
    Search: Search,
};
