"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var search_reducer_1 = require("./search.reducer");
var getSearchState = store_1.createFeatureSelector(search_reducer_1.SEARCH_FEATURE_KEY);
var ɵ0 = function (state) {
    return state.results;
};
exports.ɵ0 = ɵ0;
var results = store_1.createSelector(getSearchState, ɵ0);
var ɵ1 = function (state) {
    for (var _i = 0, _a = ['loading']; _i < _a.length; _i++) {
        var key = _a[_i];
        if (key in state && state[key]) {
            return true;
        }
    }
    return false;
};
exports.ɵ1 = ɵ1;
var isWorking = store_1.createSelector(getSearchState, ɵ1);
exports.searchQuery = {
    isWorking: isWorking,
    results: results,
};
