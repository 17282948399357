"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/elements/modal/src");
var images_rest_api_service_1 = require("./images-rest-api.service");
var src_2 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../../../../elements/modal/src/lib/services/modal.service");
var i3 = require("@angular/common/http");
var PmpImagesRestApiService = /** @class */ (function (_super) {
    __extends(PmpImagesRestApiService, _super);
    function PmpImagesRestApiService(store, modalService, http) {
        var _this = _super.call(this, store, modalService, http) || this;
        _this.store = store;
        _this.modalService = modalService;
        _this.http = http;
        return _this;
    }
    PmpImagesRestApiService.prototype.defaultHttpParameters = function () {
        return this.store.pipe(store_1.select(src_2.userQuery.getUser), operators_1.filter(function (state) { return !!state; }), operators_1.map(function (user) { return ({
            Authorization: "Bearer " + user.apiToken,
            MembershipId: user.memberships[0].id,
            CustomerId: user.memberships[0].customerId,
        }); }), operators_1.timeout(1000));
    };
    PmpImagesRestApiService.ngInjectableDef = i0.defineInjectable({ factory: function PmpImagesRestApiService_Factory() { return new PmpImagesRestApiService(i0.inject(i1.Store), i0.inject(i2.ModalService), i0.inject(i3.HttpClient)); }, token: PmpImagesRestApiService, providedIn: "root" });
    return PmpImagesRestApiService;
}(images_rest_api_service_1.ImagesRestApiService));
exports.PmpImagesRestApiService = PmpImagesRestApiService;
