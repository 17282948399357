import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { RestApiService } from './services/rest-api.service';
import { SearchComponent } from './components/search/search.component';
import { EffectsModule } from '@ngrx/effects';
import {
    SEARCH_FEATURE_KEY,
    initialState as searchInitialState,
    searchReducer,
} from './+state/search.reducer';
import { SearchEffects } from './+state/search.effects';
import { ResultComponent } from './components/result/result.component';
import { ResultComponentService } from './services/result-component.service';
import { ManagerComponent } from './components/result/manager/manager.component';
import { PropertyComponent } from './components/result/property/property.component';
import { ResidentComponent } from './components/result/resident/resident.component';
import { InvestorComponent } from './components/result/investor/investor.component';
import { JobComponent } from './components/result/job/job.component';

const RESULTS_COMPONENTS = [
    InvestorComponent,
    JobComponent,
    ManagerComponent,
    PropertyComponent,
    ResidentComponent,
];

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(SEARCH_FEATURE_KEY, searchReducer, { initialState: searchInitialState }),
        EffectsModule.forFeature([SearchEffects]),
    ],
    providers: [RestApiService, ResultComponentService],
    declarations: [SearchComponent, ResultComponent, ...RESULTS_COMPONENTS],
    exports: [SearchComponent],
    entryComponents: [...RESULTS_COMPONENTS],
})
export class ElementsSearchModule {}
