"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var models_1 = require("../../models/");
var result_component_service_1 = require("../../services/result-component.service");
var ResultComponent = /** @class */ (function () {
    function ResultComponent(componentFactoryResolver, resultComponentService) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.resultComponentService = resultComponentService;
        this.ngUnsubscribe = new rxjs_1.Subject();
    }
    ResultComponent.prototype.ngOnChanges = function () {
        this.showResult();
    };
    ResultComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ResultComponent.prototype.destroyChildComponent = function () {
        if (this.componentReference) {
            this.componentReference.destroy();
            this.componentReference = undefined;
        }
    };
    ResultComponent.prototype.showResult = function () {
        if (!this.result) {
            this.destroyChildComponent();
            return;
        }
        if (!this.componentReference) {
            var componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.resultComponentService.resolveComponent(this.result.modelClass));
            this.componentReference = this.resultContainer.createComponent(componentFactory);
            this.componentInstance = this.componentReference.instance;
        }
        this.componentInstance.result = this.result;
    };
    return ResultComponent;
}());
exports.ResultComponent = ResultComponent;
