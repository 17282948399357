import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector } from '@angular/core';
import { ComponentPortal, DomPortalHost, Portal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalComponent } from '../components/modal/modal.component';
import { ErrorComponent } from '../components/error/error.component';
import { MessageComponent } from '../components/message/message.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { ModalEventModel } from '../models/modal-event.model';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private bodyPortalHost: DomPortalHost;
    private componentRef;
    private ngUnsubscribe = new Subject<void>();
    private modalPortal: ComponentPortal<ModalComponent>;
    private componentClass: any;
    public events = new Subject<ModalEventModel>();
    public close = new Subject<any>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector
    ) {
        this.modalPortal = new ComponentPortal(ModalComponent);
        this.bodyPortalHost = new DomPortalHost(
            document.body,
            this.componentFactoryResolver,
            this.applicationRef,
            this.injector
        );
    }

    error(error?: string) {
        this.show(ErrorComponent, { error });
    }

    message(message: string, title?: string) {
        this.show(MessageComponent, { message, title });
    }

    /**
     * Launches a modal with a message and which asks for a confirmation.
     *
     * Returns a promise that will be either resolved (if the user selects "Yes")
     * or rejected (if the user selects "No" or closes the modal).
     */
    confirm(message: string, title?: string) {
        return new Promise<void>((resolve, reject) => {
            this.show(ConfirmComponent, { message, title }, ['yes', 'no']);
            this.events.subscribe(event => {
                this.hide();
                if (event.name === 'yes') {
                    resolve();
                } else {
                    reject();
                }
            });
            this.close.subscribe(() => {
                reject();
            });
        });
    }

    show(componentClass: any, inputData?: any, outputs?: string[]) {
        if (this.componentRef) {
            this.hide();
        }

        this.componentClass = componentClass;
        this.componentRef = this.bodyPortalHost.attach(this.modalPortal);
        this.componentRef.instance.componentClass = this.componentClass;
        if (outputs) {
            this.componentRef.instance.events = outputs;
            this.componentRef.instance.childEvents.subscribe(event => {
                this.events.next(event);
            });
        }
        if (inputData) {
            this.setComponentInput(inputData);
        }
        this.componentRef.instance.close.subscribe(() => {
            this.hide();
            this.close.next(this.componentClass);
        });
    }

    setComponentInput(inputData: any) {
        if (!this.componentRef) {
            throw new Error('Cannot set component input: modal is not open');
        }

        this.componentRef.instance.componentData = inputData;
        this.componentRef.changeDetectorRef.detectChanges();
    }

    hide() {
        this.bodyPortalHost.detach();
        this.componentRef = null;
        this.componentClass = null;
    }
}
