<div class="search-result">
    <div class="img-holder"><img [src]="profileUrl" alt="" /></div>
    <div class="inner">
        <div class="description">
            <h2 (click)="select($event)" class="clickable">{{ firstName }} {{ lastName }}</h2>
            <ul class="item-list">
                <li>Investor</li>
                <li *ngIf="email">
                    Email: <a *ngIf="email" [href]="'mailto: ' + email">{{ email }}</a>
                </li>
                <li *ngIf="phone">
                    Phone: <a *ngIf="phone" [href]="'tel: ' + phone">{{ phone }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
