import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { sidenavQuery } from '@fixiti/state/sidenav/src';
import { Go, KickUser, Logout, ToggleSidenav } from '@fixiti/actions/src';

@Component({
    selector: 'fixiti-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.css'],
})
export class MainMenuComponent {
    constructor(private store: Store<any>) {}

    isOpen$ = this.store.pipe(select(sidenavQuery.getIsSidenavOpen));

    close(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new ToggleSidenav());
    }

    go(url: string, event: MouseEvent) {
        this.close(event);
        this.store.dispatch(new Go({ path: [url] }));
    }

    logout(event: MouseEvent) {
        this.close(event);
        this.store.dispatch(new Logout());
        this.store.dispatch(new Go({ path: ['/login'] }));
    }
}
