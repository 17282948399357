<form class="property-form">
    <h1 *ngIf="title">{{ title }}</h1>
    {{ message }}
    <div class="continue">Continue?</div>

    <div class="btn-row">
        <div class="btn-holder">
            <input type="submit" class="btn btn-green" (click)="confirm($event)" value="Yes" />
        </div>
        <div class="btn-holder"><a href="#" class="btn btn-red" (click)="cancel($event)">No</a></div>
    </div>
</form>
