import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { searchQuery } from '../../+state/search.selectors';
import { Search } from '../../+state/search.actions';

@Component({
    selector: 'fixiti-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    private searchTerms = new Subject<string>();
    results$ = this.store.pipe(select(searchQuery.results));
    showResults = false;

    constructor(private store: Store<any>) {}

    ngOnInit() {
        this.searchTerms
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter(term => term.length > 2),
                distinctUntilChanged(),
                debounceTime(250)
            )
            .subscribe(term => {
                this.store.dispatch(new Search(term));
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    searchTerm(term: string) {
        this.showResults = term.length > 2;
        this.searchTerms.next(term);
    }
}
