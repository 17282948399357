import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalEventModel } from '../../models/modal-event.model';

@Component({
    selector: 'fixiti-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements AfterViewInit, OnChanges, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    private componentInstance: any;
    private componentReference;
    public _width: string;

    @Input() componentClass: any;
    @Input() componentData: any;
    @Input() events: string[] = [];
    @Input() minHeight: number;
    @Input()
    set width(width: number | string) {
        if (typeof width === 'number') {
            this._width = '' + width + 'px';
        } else {
            this._width = width;
            const lastCharacter = this._width.substring(this._width.length - 1);
            if (lastCharacter !== '%' && lastCharacter !== 'x') {
                this._width = this._width + 'px';
            }
        }
    }
    @Output() close = new EventEmitter();
    @Output() childEvents = new EventEmitter<ModalEventModel>();
    @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: any;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnChanges() {
        this.refreshComponent();
    }

    ngAfterViewInit() {
        this.refreshComponent();
    }

    squelch(event: MouseEvent) {
        event.stopPropagation();
    }

    doClose(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.close.emit();
    }

    private refreshComponent() {
        if (!this.componentClass) {
            return;
        }

        if (!this.componentReference) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                this.componentClass
            );
            this.componentContainer.clear();
            this.componentReference = this.componentContainer.createComponent(componentFactory);
            this.componentInstance = this.componentReference.instance;

            // take care of outputs
            for (const event of this.events) {
                if (!this.componentInstance[event]) {
                    throw new Error(
                        'Asked to subscribe to Output ' +
                            event +
                            ' on component ' +
                            this.componentClass +
                            ' but component instance has no such Output'
                    );
                }

                this.componentInstance[event]
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((eventData: any) => {
                        this.childEvents.emit(<ModalEventModel>{ name: event, data: eventData });
                    });
            }
        }

        // take care of inputs
        if (this.componentData) {
            for (const key of Object.keys(this.componentData)) {
                this.componentInstance[key] = this.componentData[key];
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (this.componentReference) {
            this.componentReference.destroy();
        }
    }
}
