import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { Go } from '@fixiti/actions/src';
import { ResultModel } from '../../../models/';

@Component({
    selector: 'fixiti-search-result-job',
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.css'],
})
export class JobComponent {
    constructor(private store: Store<any>) {}

    resourceId = null;
    workOrderNumber = '';
    address = '';
    city = '';
    state = '';
    zip = '';
    status = '';
    residentName = '';
    residentEmail = '';
    scheduleStartTime = 0;
    scheduleEndTime = 0;
    frontElevationUrl = '';

    @Input()
    set result(result: ResultModel) {
        this.resourceId = result.resourceId;
        this.workOrderNumber = result.data.work_order_number || '';
        this.address = result.data.address || '';
        this.city = result.data.city || '';
        this.state = result.data.state || '';
        this.zip = result.data.zip || '';
        this.status = result.data['status_id.name'] || '';
        this.residentName = result.data.resident_name || '';
        this.residentEmail = result.data.resident_email || '';
        this.frontElevationUrl = result.data.front_elevation_url || '';
        const startTime = result.data.schedule_start_time || 0;
        const endTime = result.data.schedule_end_time || 0;
        this.scheduleStartTime = startTime ? 1000 * startTime : 0;
        this.scheduleEndTime = endTime ? 1000 * endTime : 0;
    }

    select(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.store.dispatch(new Go({ path: [`maintenance/view/${this.resourceId}`] }));
    }
}
