import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fixiti-modal-confirmationn',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css'],
})
export class ConfirmComponent {
    @Input() title: string;
    @Input() message: string;
    @Output() no = new EventEmitter<void>();
    @Output() yes = new EventEmitter<void>();

    private squelch(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    confirm(event: MouseEvent) {
        this.squelch(event);
        this.yes.emit();
    }

    cancel(event: MouseEvent) {
        this.squelch(event);
        this.no.emit();
    }
}
