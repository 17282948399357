<form class="search-form">
    <div class="form-group">
        <input placeholder="Universal Search Bar…" type="text" (keyup)="searchTerm($event.target.value)" />
        <button class="btn-search">
            <img alt="" height="22" src="assets/icons/ico-search.svg" width="25" />
        </button>
    </div>
    <ul class="search-results" *ngIf="showResults">
        <li *ngFor="let result of (results$ | async)">
            <fixiti-search-result [result]="result"> </fixiti-search-result>
        </li>
    </ul>
</form>
