import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { ModalService } from '@fixiti/elements/modal/src';
import { RestApiBase } from '@fixiti/api/rest/rest-api.base';
import { userQuery } from '@fixiti/state/user/src';
import { SharedApiNormalizationService } from '@fixiti/shared/api/normalization/src/lib/shared-api-normalization.service';
import { ResultModel } from '../models/';
import { environmentQuery } from '@fixiti/state/environment/src';

@Injectable({
    providedIn: 'root',
})
export class RestApiService extends RestApiBase {
    constructor(
        protected store: Store<any>,
        protected modalService: ModalService,
        protected http: HttpClient,
        protected normalize: SharedApiNormalizationService
    ) {
        super(store, modalService);
    }

    get serverObs() {
        return this.store.pipe(select(environmentQuery.getSearchUrl));
    }

    search(term: string): Observable<ResultModel[]> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) => {
                return this.http.get(`${server}?q=${term}`, { headers });
            }),
            this.checkForError(),
            map(results => {
                return results.data.map(record => {
                    return this.normalizeRecord(record);
                });
            }),
            catchError(error => this.handleError(error))
        );
    }

    defaultHttpParameters(): Observable<any> {
        return this.store.pipe(
            select(userQuery.activeSearchJwt),
            map(jwt => {
                return {
                    Authorization: jwt,
                };
            }),
            take(1)
        );
    }

    normalizeRecord(record: any): ResultModel {
        const data = typeof (record.cache || null) === 'string' ? JSON.parse(record.cache) : {};
        return {
            id: this.normalize.asString(record.id),
            resourceId: this.normalize.asInt(record.resource_id),
            modelClass: this.normalize.asString(record.model_class),
            data: data,
        };
    }
}
