import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SEARCH_FEATURE_KEY, SearchState } from './search.reducer';

const getSearchState = createFeatureSelector<SearchState>(SEARCH_FEATURE_KEY);

const results = createSelector(
    getSearchState,
    (state: SearchState) => {
        return state.results;
    }
);

const isWorking = createSelector(
    getSearchState,
    (state: SearchState) => {
        for (const key of ['loading']) {
            if (key in state && state[key]) {
                return true;
            }
        }

        return false;
    }
);

export const searchQuery = {
    isWorking,
    results,
};
