"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var investor_component_1 = require("../components/result/investor/investor.component");
var job_component_1 = require("../components/result/job/job.component");
var manager_component_1 = require("../components/result/manager/manager.component");
var property_component_1 = require("../components/result/property/property.component");
var resident_component_1 = require("../components/result/resident/resident.component");
var i0 = require("@angular/core");
var ResultComponentService = /** @class */ (function () {
    function ResultComponentService() {
        this.map = {
            modelsjobhubjob: job_component_1.JobComponent,
            modelsproperty_managementinvestor: investor_component_1.InvestorComponent,
            modelsproperty_managementmanager: manager_component_1.ManagerComponent,
            modelsproperty_managementproperty: property_component_1.PropertyComponent,
            modelsproperty_managementresident: resident_component_1.ResidentComponent,
        };
    }
    /**
     * Takes the modelClass from a result and returns the component to use to display the result
     *
     * This is here because the server tells us which kind of result to display, which comes down
     * the only way it can - as a string.  We then need to turn that string into an actual component.
     */
    ResultComponentService.prototype.resolveComponent = function (modelClass) {
        if (!(modelClass in this.map)) {
            throw new Error("Cannot resolve unknown modelClass '" + modelClass + "'");
        }
        return this.map[modelClass];
    };
    ResultComponentService.ngInjectableDef = i0.defineInjectable({ factory: function ResultComponentService_Factory() { return new ResultComponentService(); }, token: ResultComponentService, providedIn: "root" });
    return ResultComponentService;
}());
exports.ResultComponentService = ResultComponentService;
