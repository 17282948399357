"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./job.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./job.component");
var i4 = require("@ngrx/store");
var styles_JobComponent = [i0.styles];
var RenderType_JobComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobComponent, data: {} });
exports.RenderType_JobComponent = RenderType_JobComponent;
function View_JobComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "'mailto' + residentEmail"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.residentName; _ck(_v, 1, 0, currVal_0); }); }
function View_JobComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.residentName; _ck(_v, 0, 0, currVal_0); }); }
function View_JobComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Resident: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noEmail", 2]], null, 0, null, View_JobComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.residentEmail; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_JobComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Scheduled: ", " ", " - ", " "])), i1.ɵppd(2, 1), i1.ɵppd(3, 2), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.scheduleStartTime)); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.scheduleStartTime, "shortTime")); var currVal_2 = i1.ɵunv(_v, 1, 2, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.scheduleEndTime, "shortTime")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_JobComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "search-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "img-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 10, "ul", [["class", "item-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Job"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", " ", " ", " ", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["Status: ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobComponent_1)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobComponent_4)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.residentName; _ck(_v, 16, 0, currVal_7); var currVal_8 = _co.scheduleStartTime; _ck(_v, 18, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frontElevationUrl; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.workOrderNumber; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.address; var currVal_3 = _co.city; var currVal_4 = _co.state; var currVal_5 = _co.zip; _ck(_v, 12, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.status; _ck(_v, 14, 0, currVal_6); }); }
exports.View_JobComponent_0 = View_JobComponent_0;
function View_JobComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-search-result-job", [], null, null, null, View_JobComponent_0, RenderType_JobComponent)), i1.ɵdid(1, 49152, null, 0, i3.JobComponent, [i4.Store], null, null)], null, null); }
exports.View_JobComponent_Host_0 = View_JobComponent_Host_0;
var JobComponentNgFactory = i1.ɵccf("fixiti-search-result-job", i3.JobComponent, View_JobComponent_Host_0, { result: "result" }, {}, []);
exports.JobComponentNgFactory = JobComponentNgFactory;
