import { Action } from '@ngrx/store';

import { ResultModel } from '../models/result.model';

export enum SearchActionTypes {
    Loaded = '[Search] Loaded',
    LoadError = '[Search] Load Error',
    Search = '[Search] Search',
}

export class Loaded implements Action {
    readonly type = SearchActionTypes.Loaded;
    constructor(public results: ResultModel[]) {}
}

export class LoadError implements Action {
    readonly type = SearchActionTypes.LoadError;
    constructor(public error: any) {}
}

export class Search implements Action {
    readonly type = SearchActionTypes.Search;
    constructor(public term: string) {}
}

export type SearchAction = Loaded | LoadError | Search;

export const fromSearchActions = {
    Loaded,
    LoadError,
    Search,
};
