"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var _a;
var search_actions_1 = require("./search.actions");
exports.SEARCH_FEATURE_KEY = 'search';
exports.initialState = {
    results: [],
    loading: false,
};
var reducers = (_a = {},
    _a[search_actions_1.SearchActionTypes.Loaded] = function (state, action) {
        return __assign({}, state, { loading: false, results: action.results });
    },
    _a[search_actions_1.SearchActionTypes.LoadError] = function (state, action) {
        return __assign({}, state, { loading: false, error: action.error });
    },
    _a[search_actions_1.SearchActionTypes.Search] = function (state, action) {
        return __assign({}, state, { loading: true });
    },
    _a);
function searchReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    if (action.type in reducers) {
        return reducers[action.type](state, action);
    }
    return state;
}
exports.searchReducer = searchReducer;
