"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/actions/src");
var src_2 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(store) {
        this.store = store;
    }
    AuthGuard.prototype.canActivate = function () {
        var _this = this;
        return this.store.pipe(store_1.select(src_2.userQuery.loggedIn), operators_1.tap(function (loggedIn) {
            if (!loggedIn) {
                _this.store.dispatch(new src_1.Go({
                    path: ['login'],
                }));
            }
        }));
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Store)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;
