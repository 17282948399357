"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/elements/modal/src");
var rest_api_base_1 = require("@fixiti/api/rest/rest-api.base");
var src_2 = require("@fixiti/state/user/src");
var shared_api_normalization_service_1 = require("@fixiti/shared/api/normalization/src/lib/shared-api-normalization.service");
var src_3 = require("@fixiti/state/environment/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../../../modal/src/lib/services/modal.service");
var i3 = require("@angular/common/http");
var i4 = require("../../../../../shared/api/normalization/src/lib/shared-api-normalization.service");
var RestApiService = /** @class */ (function (_super) {
    __extends(RestApiService, _super);
    function RestApiService(store, modalService, http, normalize) {
        var _this = _super.call(this, store, modalService) || this;
        _this.store = store;
        _this.modalService = modalService;
        _this.http = http;
        _this.normalize = normalize;
        return _this;
    }
    Object.defineProperty(RestApiService.prototype, "serverObs", {
        get: function () {
            return this.store.pipe(store_1.select(src_3.environmentQuery.getSearchUrl));
        },
        enumerable: true,
        configurable: true
    });
    RestApiService.prototype.search = function (term) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "?q=" + term, { headers: headers });
        }), this.checkForError(), operators_1.map(function (results) {
            return results.data.map(function (record) {
                return _this.normalizeRecord(record);
            });
        }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    RestApiService.prototype.defaultHttpParameters = function () {
        return this.store.pipe(store_1.select(src_2.userQuery.activeSearchJwt), operators_1.map(function (jwt) {
            return {
                Authorization: jwt,
            };
        }), operators_1.take(1));
    };
    RestApiService.prototype.normalizeRecord = function (record) {
        var data = typeof (record.cache || null) === 'string' ? JSON.parse(record.cache) : {};
        return {
            id: this.normalize.asString(record.id),
            resourceId: this.normalize.asInt(record.resource_id),
            modelClass: this.normalize.asString(record.model_class),
            data: data,
        };
    };
    RestApiService.ngInjectableDef = i0.defineInjectable({ factory: function RestApiService_Factory() { return new RestApiService(i0.inject(i1.Store), i0.inject(i2.ModalService), i0.inject(i3.HttpClient), i0.inject(i4.SharedApiNormalizationService)); }, token: RestApiService, providedIn: "root" });
    return RestApiService;
}(rest_api_base_1.RestApiBase));
exports.RestApiService = RestApiService;
