<div class="search-result">
    <div class="img-holder"><img [src]="frontElevationUrl" alt="" /></div>
    <div class="inner">
        <div class="description">
            <h2 (click)="select($event)" class="clickable">{{ address }} {{ city }} {{ state }} {{ zip }}</h2>
            <ul class="item-list">
                <li>Property</li>
                <li *ngIf="investor && (investor.firstName || investor.lastName)">
                    Investor: {{ investor.firstName }} {{ investor.lastName }}
                </li>
                <li *ngFor="let resident of residents">
                    Resident: {{ resident.firstName }} {{ resident.lastName }}
                </li>
            </ul>
        </div>
    </div>
</div>
