import { Injectable } from '@angular/core';

import { InvestorComponent } from '../components/result/investor/investor.component';
import { JobComponent } from '../components/result/job/job.component';
import { ManagerComponent } from '../components/result/manager/manager.component';
import { PropertyComponent } from '../components/result/property/property.component';
import { ResidentComponent } from '../components/result/resident/resident.component';

@Injectable({
    providedIn: 'root',
})
export class ResultComponentService {
    private map: { [key: string]: any } = {
        modelsjobhubjob: JobComponent,
        modelsproperty_managementinvestor: InvestorComponent,
        modelsproperty_managementmanager: ManagerComponent,
        modelsproperty_managementproperty: PropertyComponent,
        modelsproperty_managementresident: ResidentComponent,
    };

    /**
     * Takes the modelClass from a result and returns the component to use to display the result
     *
     * This is here because the server tells us which kind of result to display, which comes down
     * the only way it can - as a string.  We then need to turn that string into an actual component.
     */
    resolveComponent(modelClass: string) {
        if (!(modelClass in this.map)) {
            throw new Error("Cannot resolve unknown modelClass '" + modelClass + "'");
        }

        return this.map[modelClass];
    }
}
