"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var manager_component_1 = require("./components/result/manager/manager.component");
var property_component_1 = require("./components/result/property/property.component");
var resident_component_1 = require("./components/result/resident/resident.component");
var investor_component_1 = require("./components/result/investor/investor.component");
var job_component_1 = require("./components/result/job/job.component");
var RESULTS_COMPONENTS = [
    investor_component_1.InvestorComponent,
    job_component_1.JobComponent,
    manager_component_1.ManagerComponent,
    property_component_1.PropertyComponent,
    resident_component_1.ResidentComponent,
];
var ElementsSearchModule = /** @class */ (function () {
    function ElementsSearchModule() {
    }
    return ElementsSearchModule;
}());
exports.ElementsSearchModule = ElementsSearchModule;
