"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var src_1 = require("@fixiti/actions/src");
var models_1 = require("../../../models/");
var PropertyComponent = /** @class */ (function () {
    function PropertyComponent(store) {
        this.store = store;
        this.resourceId = null;
        this.address = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.frontElevationUrl = '';
        this.investor = null;
        this.residents = [];
    }
    Object.defineProperty(PropertyComponent.prototype, "result", {
        set: function (result) {
            this.resourceId = result.resourceId;
            this.address = result.data.address || '';
            this.city = result.data.city || '';
            this.state = result.data.state || '';
            this.zip = result.data.zip || '';
            this.frontElevationUrl = result.data.front_elevation_url || '';
            var investor = result.data.investor || {};
            this.investor = {
                firstName: investor.first_name || '',
                lastName: investor.last_name || '',
                email: investor.email || '',
                phone: investor.phone || '',
            };
            this.residents = (investor.resident_list || []).map(function (resident) {
                return {
                    firstName: resident.first_name || '',
                    lastName: resident.last_name || '',
                    email: resident.email || '',
                    phone: resident.phone || '',
                };
            });
        },
        enumerable: true,
        configurable: true
    });
    PropertyComponent.prototype.select = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new src_1.Go({ path: ["properties/view/" + this.resourceId] }));
    };
    return PropertyComponent;
}());
exports.PropertyComponent = PropertyComponent;
