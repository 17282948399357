import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ResultModel } from '../../models/';
import { ResultComponentService } from '../../services/result-component.service';

@Component({
    selector: 'fixiti-search-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.css'],
})
export class ResultComponent implements OnChanges, OnDestroy {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private resultComponentService: ResultComponentService
    ) {}

    private ngUnsubscribe = new Subject<void>();
    private componentInstance: any;
    private componentReference: any;

    @Input() result: ResultModel;
    @ViewChild('resultContainer', { read: ViewContainerRef }) resultContainer: any;

    ngOnChanges() {
        this.showResult();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    destroyChildComponent() {
        if (this.componentReference) {
            this.componentReference.destroy();
            this.componentReference = undefined;
        }
    }

    private showResult() {
        if (!this.result) {
            this.destroyChildComponent();
            return;
        }

        if (!this.componentReference) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                this.resultComponentService.resolveComponent(this.result.modelClass)
            );
            this.componentReference = this.resultContainer.createComponent(componentFactory);
            this.componentInstance = this.componentReference.instance;
        }

        this.componentInstance.result = this.result;
    }
}
