import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { Go } from '@fixiti/actions/src';
import { ResultModel } from '../../../models/';

@Component({
    selector: 'fixiti-search-result-manager',
    templateUrl: './manager.component.html',
    styleUrls: ['./manager.component.css'],
})
export class ManagerComponent {
    constructor(private store: Store<any>) {}

    resourceId = 0;
    email = '';
    firstName = '';
    lastName = '';
    phone = '';
    profileUrl = '';

    @Input()
    set result(result: ResultModel) {
        this.resourceId = result.resourceId;
        this.email = result.data.email || '';
        this.firstName = result.data.first_name || '';
        this.lastName = result.data.last_name || '';
        this.phone = result.data.phone || '';
        this.profileUrl = result.data.profile_url || '';
    }

    select(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.store.dispatch(new Go({ path: [`users/view/${this.resourceId}`] }));
    }
}
