"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var src_1 = require("@fixiti/actions/src");
var models_1 = require("../../../models/");
var JobComponent = /** @class */ (function () {
    function JobComponent(store) {
        this.store = store;
        this.resourceId = null;
        this.workOrderNumber = '';
        this.address = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.status = '';
        this.residentName = '';
        this.residentEmail = '';
        this.scheduleStartTime = 0;
        this.scheduleEndTime = 0;
        this.frontElevationUrl = '';
    }
    Object.defineProperty(JobComponent.prototype, "result", {
        set: function (result) {
            this.resourceId = result.resourceId;
            this.workOrderNumber = result.data.work_order_number || '';
            this.address = result.data.address || '';
            this.city = result.data.city || '';
            this.state = result.data.state || '';
            this.zip = result.data.zip || '';
            this.status = result.data['status_id.name'] || '';
            this.residentName = result.data.resident_name || '';
            this.residentEmail = result.data.resident_email || '';
            this.frontElevationUrl = result.data.front_elevation_url || '';
            var startTime = result.data.schedule_start_time || 0;
            var endTime = result.data.schedule_end_time || 0;
            this.scheduleStartTime = startTime ? 1000 * startTime : 0;
            this.scheduleEndTime = endTime ? 1000 * endTime : 0;
        },
        enumerable: true,
        configurable: true
    });
    JobComponent.prototype.select = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new src_1.Go({ path: ["maintenance/view/" + this.resourceId] }));
    };
    return JobComponent;
}());
exports.JobComponent = JobComponent;
